.stock-heading {
    font-size: 1.25rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.0075em;
}

.MuiGrid-container {
    margin-top: 10px !important;
}

.MuiAccordionDetails-root {
    border-bottom: 1px solid #DDDDDD;
}

.column-left{ float: left; width: 33.333%; }
.column-right{ float: right; width: 33.333%; }
.column-center{ display: inline-block; width: 33.333%; }
.stock-type-color{
    color: #808080 !important;
}
.custom-margin-top{
    margin-top: -8px !important;
}
.tbody {
    height: 100px;
    overflow-y: auto;
    width: 100%;
  }
  
  .tbody::-webkit-scrollbar {
    width: 10px;
  }
  .screen-row{
    display: inline-block;
    width: 100%;
  }
  .tbody::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #9e9e9e;
    border-radius: 10px;
  }
  
  .tbody::-webkit-scrollbar-thumb {
    background: #9E9E9E;
    border-radius: 10px;
  }
  
  .tbody::-webkit-scrollbar-thumb:hover {
    background: #9E9E9E;
  }
  
  .laser-row {
    width: 50%;
    text-align: left;
  }
  
  .laser-row:first-child {
    text-align: right;
  }
  
  .live-screen {
    margin: 0 !important;
    align-items: center;
    padding: 8px 0;
  }
  
  .table-bottom {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
  }
  .stock-location-master .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child{
      font-size: 14px !important;
  }