body {
  font-family: "Poppins";
  -webkit-font-smoothing: initial;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5 !important;
  margin: 0px;
}

#root {
  background-color: #f5f5f5;
}

.childTable {
  width: 98%;
}

.childTable .MuiToolbar-gutters {
  min-height: 0px;
  /* display: none; */
}

.childTable .MuiToolbar-root {
  display: block;
}
.parentTable {
  display: block;
}
.AccordianHeader .MuiAccordionSummary-content {
  margin: 0px 0px 12px 0px;
}

.Fromtablebetweenspace .MuiPaper-root {
  padding-right: 10px;
}

.Totablebetweenspace .MuiPaper-root {
  padding-left: 10px;
}

.kapanwisestock .MuiPaper-root {
  padding-left: 5px;
}

.AccordianHeaderBranchMaster .WithStyles\(ForwardRef\(AccordionSummary\)\)-root-22 {
  width: 98.5%;
}

.AccordianHeaderBranchMaster .MuiAccordionSummary-content {
  margin: 0px 0px 10px 0px;
}

.sub-packet {
  height: 25px;
  font-size: 12px !important;
  width: auto;
  border-radius: 20px !important;
  padding: 10px !important;
}

.MuiDialog-paperFullWidth {
  min-height: 400px !important;
}

.SubPacketTable {
  width: 100%;
}

.SubPacketTable .MuiToolbar-gutters {
  min-height: 0px;
  /* display: none; */
}

.printIcon {
  font-size: 18px !important;
  padding-bottom: 10px !important;
  color: rgb(70, 81, 142) !important;
  text-align: end;
  margin-right: 28px;
  margin-top: 27px;
}
.DenseTable table td {
  padding: 0%;
}
.disableTextBox .MuiInputBase-input.Mui-disabled {
  color: black;
}
.disableTextBox .MuiFormLabel-root.Mui-disabled {
  color: black;
}
.disableTextBox label + .MuiInput-formControl {
  margin-top: 11px;
}
.disableTextBox .MuiInput-underline:before {
  border-bottom: 0px;
}

/* start new style for bg whitesmoke */

.MuiGrid-root {
  background-color: #f5f5f5 !important;
}

.MuiTableRow-root.MuiTableRow-hover {
  background-color: #f5f5f5 !important;
}

.MuiTableRow-root.MuiTableRow-hover:hover {
  background-color: #8cb836 !important;
}

.MuiTable-root {
  background-color: #f5f5f5 !important;
}

.MuiPaper-root {
  background-color: #f5f5f5 !important;
}

/* .MuiTableRow-root{
  background-color: #f5f5f5 !important;
} */

.MuiTableRow-root:hover {
  background-color: #8cb836 !important;
}

.MuiTableRow-root.MuiTableRow-footer:hover {
  background-color: #f5f5f5 !important;
}

.MuiButton-containedPrimary:hover {
  background-color: #8cb836 !important;
}

.MuiButton-containedSecondary:hover {
  background-color: #8cb836 !important;
}

.MuiButton-containedPrimary {
  background-color: #8cb836 !important;
}

.increaseWidth .MuiFormControl-root {
  width: -webkit-fill-available;
}

#filterresposiveDesign .MuiFormControl-root {
  width: 90% !important;
}
#filterresposiveDesign .selectOption .MuiInputBase-root {
  width: 90% !important;
}

/* end new style for bg whitesmoke */

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td {
  border: 1px solid #dddddd;
}

th {
  text-align: left;
  /* background-color: transparent !important; */
  border: 1px solid #dddddd;
}

.MuiTableCell-sizeSmall {
  padding-left: 5px !important;
  padding-right: 5px !important;
  padding-top: 0px !important;
  padding-bottom: 6px !important;
  text-align: center !important;
}

.MuiTableCell-root {
  text-align: center !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  padding-top: 0px !important;
  padding-bottom: 6px !important;
  border-bottom: 0px !important;
}

.stockCustomTableComponent .MuiTable-root {
  table-layout: fixed !important;
}

.childTable .MuiToolbar-gutters {
  text-align: left;
}

.virtulize-body .ReactVirtualized__List{
  overflow: overlay !important;
}

.virtulize-body .MuiTableCell-root >div{
  display: block !important;
}