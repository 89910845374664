.tfa-main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.tfa-main .tfa-card {
    border-radius: 15px;
}
.tfa-main .tfa-card .tfa-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.tfa-main .tfa-card .tfa-data .tfa-title {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 0;
}
.tfa-main .tfa-card .tfa-data .tfa-subTitle {
    font-size: 18px;
    color: #c7c6c6;
}
.tfa-main .tfa-card .tfa-data .otpInput div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 25px 0;
}
.tfa-main .tfa-card .tfa-data .otpInput div input {
    margin: 0 5px;
    width: 40px;
    height: 52px;
    border: 2px solid #1a1b1c;
    font-size: 40px;
    padding: 0 10px;
}
.tfa-main .tfa-card .tfa-data .tfa-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
}
.tfa-main .tfa-card .tfa-data .tfa-footer button {
    width: 40%;
    padding: 0px;
    height: 40px;
    font-weight: 700;
    font-size: 16px;
    margin: 10px 0;
}
.tfa-main .tfa-card .tfa-data .tfa-footer a {
    color: skyblue;
    font-size: 16px;
}
.tfa-main a {
    color: skyblue;
    text-align: center;
}
.disable-input {
    margin-bottom: 1px;
    border: 1px solid rgb(195, 195, 195);
    border-radius: 5px !important;
    padding: 5px 10px;
    width: 100%;
    height: 40px;
}
