.stock-heading {
    font-size: 1.25rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.0075em;
}

.MuiGrid-container {
    margin-top: 10px !important;
}

.MuiAccordionDetails-root {
    border-bottom: 1px solid #dddddd;
}

.column-left {
    float: left;
    width: 33.333%;
}
.column-right {
    float: right;
    width: 33.333%;
}
.column-center {
    display: inline-block;
    width: 33.333%;
}
.stock-type-color {
    color: #808080 !important;
}
.Kapan-wise-ReportExcel button {
    position: absolute;
    z-index: 9999;
    top: 7px;
    background-color: transparent;
    border-color: transparent;
    left: 50px;
}
