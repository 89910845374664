.stock-heading {
    font-size: 1.25rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.0075em;
}

.MuiGrid-container {
    margin-top: 10px !important;
}

.MuiAccordionDetails-root {
    border-bottom: 1px solid #dddddd;
}

.column-left {
    float: left;
    width: 33.333%;
}

.column-right {
    float: right;
    width: 33.333%;
}

.column-center {
    display: inline-block;
    width: 33.333%;
}

.stock-type-color {
    color: #808080 !important;
}
.download-table-xls-button {
    position: absolute;
    z-index: 9999;
    right: 60px;
    top: 20px;
    background-color: transparent;
    border-color: transparent;
}
.download-table-xls-button :focus{
    background-color: transparent;
    border-color: transparent;
}

.download-table-xls-button-for-polish-live-screen :focus{
    background-color: transparent;
    border-color: transparent;
}

.download-table-xls-button-for-polish-live-screen{
    position: absolute;
    z-index: 9999;
    top: -5px;
    background-color: transparent;
    border-color: transparent;
}