.polish-live-screen {
  margin: 0 !important;
  align-items: center;
  padding: 8px 0;
}

.divWidth {
  width: 73.66% !important;
}

.pr-5 {
  padding-right: 5px;
}
.pr-10 {
  padding-right: 10px;
}