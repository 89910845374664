.tbody {
  height: 200px;
  overflow-y: auto;
  width: 100%;
}

.tbody::-webkit-scrollbar {
  width: 10px;
}

.screen-row {
  display: inline-block;
  width: 100%;
}

.tbody::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #9e9e9e;
  border-radius: 10px;
}

.tbody::-webkit-scrollbar-thumb {
  background: #9E9E9E;
  border-radius: 10px;
}

.tbody::-webkit-scrollbar-thumb:hover {
  background: #9E9E9E;
}

.laser-row {
  width: 50%;
  text-align: left;
}

.laser-row:first-child {
  text-align: right;
}

.live-screen {
  margin: 0 !important;
  align-items: center;
  padding: 8px 0;
}

.table-bottom {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}